<template>
  <v-container class="my-4 py-6">
    <h2>
      Distribuidores
      <v-btn
        class="mx-4 py-4"
        fab
        small
        color="primary"
        @click="showForm = !showForm"
      >
        <v-icon dark>
          {{ this.showForm ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </v-btn>
    </h2>
    <v-row
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-card
        v-if="showForm"
        class="px-4 py-4 mx-auto"
        :max-width="maxWidth"
        min-width="500"
      >
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ currentTitle }}</span>
          <v-avatar
            color="primary lighten-2"
            class="subheading white--text"
            size="24"
            v-text="step"
          ></v-avatar>
        </v-card-title>

        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text>
              <v-text-field
                v-model="nombre_distribuidor"
                label="Nombre de distribuidor"
                value=""
              ></v-text-field>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="2">
            <dato-fiscal-simple 
              :datosFiscales="datos_fiscales_distribuidor"
              :usosFactura="usosFactura" />
          </v-window-item>

          <v-window-item :value="3" >
            <v-card-text >
            <contacto-simple
              :infoContacto="contacto_distribuidor"
              :useMaternalName="true" />
            </v-card-text>
          </v-window-item>

          <v-window-item :value="4">
            <v-card-text >
              <usuario-simple 
                :usuario="usuario_empleado" />
            </v-card-text>
            
          </v-window-item>

          <v-window-item :value="5">
            <v-card-text >
            <contacto-simple 
              :infoContacto="contacto_usuario_empleado" />
            </v-card-text>
          </v-window-item>
          
          <v-window-item :value="6">
            <direccion-simple 
              :direccion="direccion_usuario_empleado" />
          </v-window-item>

          <v-window-item :value="7">
            <dato-fiscal-simple 
              :datosFiscales="datos_fiscales_usuario_empleado"
              :usosFactura="usosFactura" />
          </v-window-item>
        </v-window>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="step === 1"
            text
            @click="step--"
          >
            Atras
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="invalidFields"
            color="primary"
            depressed
            @click="nextStep"
          >
            {{ nextAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <distribuidor :distribuidores="distribuidores" />
  </v-container>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'

export default {
  data() {
    return {
      step: 1,
      showForm: false,
      usosFactura: [],
      distribuidores: [],
      nombre_distribuidor: null,
      datos_fiscales_distribuidor: {
        rfc: null,
        razon_social: null,
        uso_factura: null
      },
      contacto_distribuidor: {
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        correo: null
      },
      usuario_empleado: {
        username: null,
        password: null,
        password_confirmation: null
      },
      contacto_usuario_empleado: {
        nombre: null,
        apellidoPaterno: null,
        correo: null,
      },
      direccion_usuario_empleado: {
        calle: null,
        numero_exterior: null,
        numero_interior: null,
        codigo_postal: null,
        colonia: null,
        ciudad: null,
        estado: null,
        numero_contacto: null
      },
      datos_fiscales_usuario_empleado: {
        rfc: null,
        razon_social: null,
        uso_factura: null,
      },
      distribuidor: {
        usuario_empleado: {
          nombreUsuario: null,
          password: null,
          correo: null,
          tipo_usuario: "Distribuidor",
          nombre: null,
          apellidoPaterno: null,
          direccion: {
            calle: null,
            numero_exterior: null,
            numero_interior: null,
            codigo_postal: null,
            colonia: null,
            ciudad: null,
            estado: null,
            numero_contacto: null,
            es_predeterminado: true,
          },
          datos_fiscales: {
            rfc: null,
            razon_social: null,
            uso_factura: null,
          }
        },
        distribuidor: {
          nombre: null,
          datos_fiscales: {
            rfc: null,
            razon_social: null,
            uso_factura: null,
          },
          contacto: {
            nombre: null,
            apellidoPaterno: null,
            apellidoMaterno: null,
            correo: null,
          }
        }
      },
    }
  },
  components: {
    DatoFiscalSimple: () => import('@/components/utils/SimpleForms/DatoFiscalSimple'),
    ContactoSimple: () => import('@/components/utils/SimpleForms/ContactoSimple'),
    UsuarioSimple: () => import('@/components/utils/SimpleForms/UsuarioSimple'),
    DireccionSimple: () => import('@/components/utils/SimpleForms/DireccionSimple'),
    Distribuidor: () => import('@/components/utils/Cards/Distribuidor'),
  },
  computed: {
    currentTitle () {
      switch (this.step) {
        case 1: return 'Nombre de distribuidor'
        case 2: return 'Datos fiscales de distribuidor'
        case 3: return 'Contacto de distribuidor'
        case 4: return 'Usuario autorizado'
        case 5: return 'Datos personales de usuario autorizado'
        case 6: return 'Dirección de usuario autorizado'
        case 7: return 'Datos fiscales de usuario autorizado'
        default: return 'Distribuidor creado'
      }
    },
    nextAction () {
      return this.step === 7 ? 'Crear' : 'Siguiente' 
    },
    invalidFields () {
      switch (this.step) {
        case 1: return this.nombre_distribuidor === null
        case 2: return this.objectHasNulls(this.datos_fiscales_distribuidor)
        case 3: return this.objectHasNulls(this.contacto_distribuidor)
        case 4: return this.objectHasNulls(this.usuario_empleado)
        case 5: return this.objectHasNulls(this.contacto_usuario_empleado)
        case 6: return this.objectHasNulls(this.direccion_usuario_empleado)
        case 7: return this.objectHasNulls(this.datos_fiscales_usuario_empleado)
        default:
          if (this.step === 8 ) {
            this.createDistributor()
          }
          return false
      }

    },
    maxWidth () {
      return this.step === 6 ? "800" : "500"
    }
  },
  methods: {
    async getUsosFactura() {
      const response = await ApiAuth.get("/personas/api/uso-factura/")
      const responseData = await response.data
      this.usosFactura = responseData
    },
    async getDistribuidores() {
      const response = await ApiAuth.get("/personas/api/distribuidor/")
      const responseData = await response.data
      // console.log("Response data distribuidores: ", responseData);
      this.distribuidores = responseData
    },
    async createDistributor () {
      this.$store.commit('setLoading', true)
      let alertType = "success"
      let alertMsg = ""
      // console.log("Distribuidor a crear: ", this.distribuidor)
      const response = await ApiAuth.post('/personas/crear-distribuidor-empleado', 
        this.distribuidor)
      const responseData = await response.data
      // console.log("responseCrearDist: ", responseData)
      if (responseData.respuesta === "enviado") {
        alertMsg = `Se creó correctamente el distribuidor: ${this.distribuidor.distribuidor.nombre} y el usuario autorizado: ${this.distribuidor.usuario_empleado.nombreUsuario}` 
      } else {
        this.step = 7
        alertType = "error"
        alertMsg = "Ocurrió un error y no se pudo crear el distribuidor"
      }
      this.$store.commit('setLoading', false)
      setTimeout(
        this.$store.commit('setAlert', {
        show: true,
        msg: alertMsg,
        type: alertType
        }), 1000
      )
    },
    nextStep() {
      this.step++
      // console.log("this.step: ", this.step);
      switch(this.step) {
        case 2: 
          this.distribuidor.distribuidor.nombre = this.nombre_distribuidor
          break
        case 3:
          this.distribuidor.distribuidor.datos_fiscales = {...this.datos_fiscales_distribuidor}
          break
        case 4:
          this.distribuidor.distribuidor.contacto = {...this.contacto_distribuidor}
          break
        case 5:
          this.distribuidor.usuario_empleado.nombreUsuario = this.usuario_empleado.username
          this.distribuidor.usuario_empleado.password = this.usuario_empleado.password
          break
        case 6:
          this.distribuidor.usuario_empleado.nombre = this.contacto_usuario_empleado.nombre
          this.distribuidor.usuario_empleado.apellidoPaterno = this.contacto_usuario_empleado.apellidoPaterno
          this.distribuidor.usuario_empleado.correo = this.contacto_usuario_empleado.correo
          break
        case 7:
          this.distribuidor.usuario_empleado.direccion = {...this.direccion_usuario_empleado}
          break
        case 8:
          this.distribuidor.usuario_empleado.datos_fiscales = {...this.datos_fiscales_usuario_empleado}                   
          break
      }
    },
    objectHasNulls(object) {
      return Object.values(object).some(value => (value === null || value === ''))
    }
  },
  created() {
    this.getUsosFactura()
    this.getDistribuidores()
  }
}
</script>

<style>

</style>